:root {
  --blue-mydna: #130999 !important;
  --yellow-mydna: #ffd65a !important;
  --black: rgba(21, 21, 21, 0.9) !important;
  --primary-ff: "Noto Sans", Helvetica, Arial, serif !important;
  --secondary-ff: "Noto Sans", Helvetica, Arial, serif !important;
  --grey: #333 !important;
  --theme-color: var(--blue-mydna) !important;
  --global-background-color: var(--blue-mydna) !important;
  --global-border-color: var(--blue-mydna) !important;
  --button-text-color: #fff !important;
  --text-hover: var(--yellow-mydna) !important;
  --contrast-color: #fff !important;
  --look-up: #ffc82f !important;
  --place-order: #66cc66 !important;
  --manage-order: #f37021 !important;
  --communicate: #00a7e1 !important;
  --sales-tips: rgb(147, 75, 201) !important;
  --pro-tips: #ff4848 !important;
  --parts-managers: #130999 !important;
  --portal-bg-image: url("../../../images/filters-composite.jpg") !important;
}

[data-theme="mydna-template"] {
  background-color: transparent !important;
  background: url("../../../images/my-dna-bg.jpg") no-repeat center center fixed !important;
  background-size: 100% 100% !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[data-theme="mydna"] {
  --theme-color: var(--blue-mydna);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --contrast-color: var(--grey);
}

[data-theme="lookup"] {
  --theme-color: var(--look-up);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --text-hover: var(--grey);
  --contrast-color: var(--grey);
  --portal-bg-image: url(../../../images/batteries-composite.jpg);
}

[data-theme="parts-managers"] {
  --theme-color: var(--parts-managers);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --text-hover: var(--grey);
  --contrast-color: var(--grey);
  --portal-bg-image: url(../../../images/batteries-composite.jpg);
}

[data-theme="place-order"] {
  --theme-color: var(--place-order);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --text-hover: var(--grey);
  --contrast-color: var(--grey);
  --portal-bg-image: url(../../../images/filters-composite.jpg);
}

[data-theme="manage-order"] {
  --theme-color: var(--manage-order);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --text-hover: var(--grey);
  --contrast-color: var(--grey);
  --portal-bg-image: url(../../../images/undercarriage-composite.jpg);
}

[data-theme="communicate"] {
  --theme-color: var(--communicate);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --text-hover: var(--grey);
  --contrast-color: var(--grey);
  --portal-bg-image: url(../../../images/communicate-composite.jpg);
}

[data-theme="sales-tips"] {
  --theme-color: var(--sales-tips);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --text-hover: var(--grey);
  --contrast-color: var(--grey);
  --portal-bg-image: url(../../../images/reman-composite.jpg);
}

[data-theme="pro-tips"] {
  --theme-color: var(--pro-tips);
  --global-border-color: var(--theme-color);
  --global-background-color: var(--theme-color);
  --text-hover: var(--grey);
  --contrast-color: var(--grey);
  --portal-bg-image: url(../../../images/hydraulics-composite.jpg);
}

.color-lookup {
  background-color: var(--look-up);
}

.color-mydna {
  background-color: var(--blue-mydna);
}

.color-place-order {
  background-color: var(--place-order);
}

/* //////// LAYOUT //////// */
.myDnaPage {
  background: inherit !important;
}

#MyDNA main {
  width: -webkit-fill-available;
  min-height: 57vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-family: "Noto Sans" !important;
  color: #000;
}

#MyDNA .flex-row {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
}

.flex-item-50 {
  position: relative;
  width: 48%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

@media screen and (max-width: 768px;) {
  .flex-item-50 {
    width: 100%;
  }
}

.flex-item-50 .portal-small p {
  font-size: 1.15rem;
  color: #fff;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.flex-item-50 h3 {
  width: 100%;
  text-align: center;
}

.flex-item-50 .portal-small {
  width: 150px;
  height: 50px;
  background-color: var(--theme-color);
  border: none;
  border-radius: 5px;
}

.flex-item-50 .portal-small:hover {
  background-color: var(--text-hover);
}

@media screen and (max-width: 768px) {
  .flex-item-50 {
    width: 100%;
  }
}

/* Typography */

#MyDNA h1 {
  font-size: clamp(1.74rem, 3vw, 40px);
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 900;
  color: var(--theme-color);
  -webkit-text-stroke: 0.5px var(--grey);
}

#MyDNA h2 {
  font-size: 45px !important;
  color: var(--blue-mydna) !important;
  font-weight: bold;
}

#MyDNA h3 {
  font-size: 26px !important;
  font-weight: 600 !important;
  color: var(--grey) !important;
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

#MyDNA p {
  font-size: 16px;
  color: var(--black);
}

#MyDNA p.note {
  font-size: 1rem;
  line-height: normal;
  font-style: italic;
}

#MyDNA a {
  color: var(--theme-color);
}

#MyDNA a:hover {
  color: var(--text-hover);
}

#MyDNA a.button:hover {
  color: var(--button-text-color);
}

#MyDNA .bold {
  font-weight: 600;
}

#MyDNA .alert {
  color: var(--pro-tips);
}

#MyDNA .center {
  text-align: center;
}

/* ///////// COMPONENTS ////////// */

#MyDNA .content-box {
  padding: 4rem;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  margin-bottom: 3rem;
  text-align: center;
}

#MyDNA .button {
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 0.5rem;
  color: var(--blue-mydna);
  background-color: transparent;
  transition-timing-function: ease-in-out;
  transition: 0.2s;
}

#MyDNA .button:hover {
  background-color: var(--theme-color);
  transform: scale(1.15);
  color: #fff;
}

#MyDNA .divider {
  width: 50%;
  height: 2px;
  border-top: 1px solid var(--global-border-color);
  margin: 5rem auto;
}

#MyDNA .mates-link {
  width: fit-content;
  align-self: flex-end;
  text-align: right;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  border: solid 1px var(--theme-color);
}

#MyDNA .mates-link:hover {
  color: #fff;
  background-color: var(--theme-color);
}

#MyDNA .callout {
  height: 300px;
  align-items: center;
  font-size: 26px;
  font-weight: 600;
  text-align: left;
  color: var(--theme-color);
  padding-left: 20px;
  background: url("../../../images/light-bulb.png") no-repeat center;
  background-size: contain;
}

#MyDNA .mates-card,
.commodity-link {
  display: inline-block;
  width: 300px;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  overflow: hidden;
  transition-timing-function: ease-in-out;
  transition: 0.2s;
  margin: 30px;
  color: var(--grey) !important;
}

#MyDNA .mates-card h5 {
  font-size: 22px !important;
  margin: 10px 0 15px 10px !important;
  font-weight: bold;
  color: inherit !important;
}

#MyDNA .mates-card img {
  margin: 0 auto 15px auto !important;
  width: 90% !important;
}

#MyDNA .mates-card:hover,
.commodity-link:hover {
  transform: scale(1.15);
  color: var(--theme-color) !important;
  box-shadow: 0 0 15px rgba(147, 75, 201, 0.5);
}

#MyDNA .commodity-link {
  width: 200px;
  height: 200px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

#MyDNA .commodity-link img {
  display: block;
  width: 75% !important;
  box-shadow: none !important;
  margin: 0 auto !important;
}

#MyDNA .content-box .commodity-link {
  width: 150px;
  height: 150px;
  background-color: #fff;
  font-size: 14px;
  margin: 20px;
  padding: 15px;
}

#MyDNA .content-box .commodity-link:hover {
  box-shadow: 0 0 15px rgba(20, 10, 154, 0.5);
}

/* /////////// HEADER ////////// */
#MyDNA .header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center !important;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px var(--grey);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

.logo {
  display: inline-block;
  width: 15%;
  margin: 10px;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 25%;
  }
}

.logo img {
  width: 100%;
}

/* ////////// SUB HEADER ////////// */

.sub-header {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.85);
}

.page-title {
  width: fit-content;
  text-align: left;
}
#MyDNA .ant-layout-header {
  height: 90px;
}

@media screen and (max-width: 410px) {
  .page-title {
    width: 100%;
  }
}

.page {
  background-color: transparent !important;
}

.page-title p {
  margin: 0;
  font-size: clamp(12px, 1.5vw, 20px);
}

.myDnaPage .sub-header,
.landing-page .sub-header {
  margin-bottom: 3rem;
  padding: 20px;
}

.myDnaPage .page-title,
.landing-page .page-title {
  width: 100%;
  text-align: center;
}

.myDnaPage .page-title h1,
.landing-page h1 {
  font-size: clamp(1.75rem, 4vw, 58px);
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 900;
  color: #130999 !important;
  -webkit-text-stroke: 0.5px var(--grey);
}

.myDnaPage .page-title p,
.landing-page .page-title p {
  font-size: clamp(14px, 1.75vw, 24px);
}

.part-lookup .page-title h1,
.child-page .page-title h1,
.parent-page .page-title h1,
.landing-page h1 {
  font-size: clamp(1.74rem, 3vw, 40px);
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 900;
  color: var(--theme-color) !important;
  -webkit-text-stroke: 0.5px var(--grey);
}

.part-lookup .sub-header,
.landing-page .sub-header {
  margin-bottom: 3rem;
  padding: 20px;
}

.part-lookup .page-title,
.landing-page .page-title {
  width: 100%;
  text-align: center;
}

.part-lookup .page-title p,
.landing-page .page-title p {
  font-size: clamp(14px, 1.75vw, 24px);
}

.pro-tips .page-title h1,
.landing-page h1 {
  font-size: clamp(1.74rem, 3vw, 40px);
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 900;
  color: var(--pro-tips) !important;
  -webkit-text-stroke: 0.5px var(--grey);
}

.pro-tips .sub-header,
.landing-page .sub-header {
  margin-bottom: 3rem;
  padding: 10px 20px;
}

.pro-tips .page-title p,
.landing-page .page-title p {
  font-size: clamp(14px, 1.75vw, 24px);
}

.parent-page .sub-header,
.landing-page .sub-header {
  margin-bottom: 3rem;
  padding: 20px;
}

.parent-page .page-title,
.landing-page .page-title {
  width: 100%;
  text-align: center;
}

.parent-page .page-title h1,
.landing-page h1 {
  font-size: clamp(1.75rem, 4vw, 58px);
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 900;
  color: var(--theme-color) !important;
  -webkit-text-stroke: 0.5px var(--grey);
}

.parent-page .page-title p,
.landing-page .page-title p {
  font-size: clamp(14px, 1.75vw, 24px);
}

.place-order .sub-header,
.landing-page .sub-header {
  margin-bottom: 3rem;
  padding: 20px;
}

.place-order .page-title,
.landing-page .page-title {
  width: 100%;
  text-align: center;
}

.place-order .page-title h1,
.landing-page h1 {
  font-size: clamp(1.75rem, 4vw, 58px);
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 900;
  color: var(--place-order) !important;
  -webkit-text-stroke: 0.5px var(--grey);
}

.place-order .page-title p,
.landing-page .page-title p {
  font-size: clamp(14px, 1.75vw, 24px);
}

a.platform-link {
  display: block !important;
  width: 185px !important;
  text-align: center !important;
  padding: 10px !important;
  border: solid 1px var(--blue-mydna) !important;
  border-radius: 5px !important;
  color: var(--blue-mydna) !important;
  text-decoration: none !important;
  font-size: 0.85rem !important;
  font-weight: 500 !important;
  z-index: 5 !important;
  align-self: center !important;
}

a.platform-link:hover {
  color: #fff !important;
  background: var(--blue-mydna) !important;
}

@media screen and (max-width: 630px) {
  a.platform-link {
    padding: 0;
    border: none;
    width: 100%;
    text-align: right;
    font-size: 12px;
    margin-top: 10px;
  }

  a.platform-link:hover {
    background-color: transparent;
    color: var(--theme-color);
  }
}

/* ////////// MAIN NAVIGATION /////////// */

.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  z-index: 100;
}

ul.menu {
  list-style: none;
  margin: 15% 0 0 0;
}

#MyDNA .menu a {
  display: block;
  padding: 20px;
  color: var(--blue-mydna);
  text-decoration: none;
  transition-timing-function: ease-in-out;
  transition: 0.2s;
  border-radius: 10px;
}

#MyDNA .menu a:hover,
#MyDNA .menu a:active {
  background-color: var(--theme-color) !important;
  transform: scale(1.05) !important;
  color: #fff !important;
}

.nav {
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

/* Menu Icon */

.hamb {
  cursor: pointer;
  align-self: center;
  margin-right: 20px;
  z-index: 200;
}

.hamb-line {
  background: var(--blue-mydna);
  display: block;
  height: 2px;
  position: relative;
  width: 24px;
}

.hamb-line::before,
.hamb-line::after {
  background: var(--blue-mydna);
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamb-line::before {
  top: 5px;
}

.hamb-line::after {
  top: -5px;
}

.side-menu {
  display: none;
}

/* Toggle menu icon */

.side-menu:checked ~ nav {
  max-height: 100%;
  z-index: 100;
}

.side-menu:checked ~ .hamb .hamb-line {
  background: transparent;
}

.side-menu:checked ~ .hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}

.side-menu:checked ~ .hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

/* Nav large screens */

@media (min-width: 1050px) {
  .nav {
    max-height: none;
    top: 0;
    position: relative;
    width: fit-content;
    background-color: transparent;
  }

  ul.menu {
    margin: 0;
  }

  .menu li {
    float: left;
  }

  #MyDNA .menu a {
    padding: 4px 8px;
    margin: 0 5px;
    font-size: 16px !important;
  }

  #MyDNA .menu a:hover,
  #MyDNA .menu a.active {
    background-color: var(--theme-color) !important;
    transform: scale(1.15) !important;
    color: #fff !important;
    border-radius: 5px !important;
    font-weight: 600 !important;
  }

  .hamb {
    display: none;
  }
}

@media screen and (min-width: 1330px) {
  .menu a {
    margin: 0 15px;
    font-size: 16px;
  }
}

/* ////// MINI NAV (SALES & COMMUNICATION PAGES) /////// */

.mini-nav {
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.mini-nav a {
  font-size: 14px;
  text-decoration: none;
  margin: 10px;
  padding: 4px 8px;
  background-color: var(--theme-color);
  color: #fff !important;
  border-radius: 5px;
}

.mini-nav a:hover {
  background-color: var(--blue-mydna);
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .mini-nav a {
    font-size: 12px;
  }
}

/* ////////// PORTAL STYLES ///////// */

a.portal,
a.portal-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 200px;
  background-color: var(--theme-color);
  border-radius: 10px;
  text-align: center;
  margin: 2rem;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  transition: 0.2s;
}

body.mydna-home a.portal {
  background-color: var(--blue-mydna);
}

a.portal-small {
  background-color: #fff;
  border: solid 2px var(--global-border-color);
  width: 300px;
  height: 175px;
}

a.portal:hover {
  background: var(--portal-bg-image);
  background-size: 100%;
  background-repeat: no-repeat;
}

body.topic-page a.portal:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
    var(--portal-bg-image);
  background-size: 100%;
  background-repeat: no-repeat;
}

a.portal:hover,
a.portal-small:hover {
  transform: scale(1.25);
}

#MyDNA .portal p {
  color: #fff;
  -webkit-text-stroke: 0.5px var(--grey);
}

#MyDNA .portal p.note,
.portal-small p.note {
  font-size: 1rem;
  color: #fff;
  margin: 0 10%;
  line-height: 1.2rem;
  font-weight: 500;
}

#MyDNA .portal p.note {
  -webkit-text-stroke: 0px;
}

#MyDNA .portal p,
.portal-small p {
  display: block;
  font-weight: 900;
  font-size: 1.8rem;
  margin: 0 10% 10px 10%;
}

#MyDNA .portal-small p,
#MyDNA .portal-small a {
  color: var(--theme-color);
}

#MyDNA .portal-small p.note {
  color: var(--black);
}

/* ////////// SWIPER SLIDER /////////// */

#MyDNA #slider {
  padding-bottom: 7.5rem;
  position: relative;
}

#MyDNA .swiper {
  height: 100%;
}

#MyDNA .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 50px;
}

#MyDNA .swiper-slide {
  text-align: left;
  font-size: 18px;
  background: #fff;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 0 20px 20px 20px;
}

#MyDNA .top-10 .swiper-slide {
  padding: 20px;
}

#MyDNA .swiper h3 {
  margin: 1rem 0 !important;
  font-size: clamp(24px, 2vw, 34px) !important;
  color: var(--theme-color) !important;
  font-weight: bold !important;
}

#MyDNA .swiper h4 {
  font-size: clamp(20px, 1.5vw, 28px) !important;
  color: var(--grey) !important;
  margin-bottom: 10px !important;
  font-weight: bold;
}

#MyDNA .swiper hr {
  display: block;
  margin: 0 0 20px 0;
  width: 30%;
  border: 0;
  border-top: solid 1px #e4e6e6;
}

#MyDNA .swiper p {
  margin-top: 0;
  width: 90%;
  font-size: clamp(12px, 1.5vw, 16px);
}

#MyDNA .swiper ol,
#MyDNA .swiper ul {
  font-size: 16px;
  width: 90%;
}

@media screen and (max-width: 768px) {
  .swiper p,
  .swiper ol {
    width: 100%;
  }
}

.swiper ol li {
  margin-bottom: 0.75rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  margin: 2rem auto 0 auto;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.swiper-pagination:before {
  content: "Steps:  ";
  color: var(--theme-color);
}

.swiper-pagination-bullet {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 23px;
  font-size: 12px;
  color: var(--theme-color);
  opacity: 1;
  background: #fff;
  border: solid 1px var(--theme-color);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: var(--theme-color);
}

ol.navigation {
  border-radius: 10px;
  list-style-type: none;
  width: 100%;
  text-align: center;
  padding-left: 0;
  z-index: 100;
}

.navigation li {
  display: inline-block;
  margin: 5px;
  position: relative;
}

.navigation li:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
}

#MyDNA .navigation li a {
  display: inline-block;
  font-size: 13px;
  line-height: 23px;
  color: var(--theme-color);
  text-decoration: none;
  background-color: #fff;
  border: solid 1px var(--theme-color);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  text-align: center;
}

#MyDNA .navigation li a.active {
  color: #fff;
  background-color: var(--theme-color);
}

@media screen and (max-width: 768px) {
  nav,
  .swiper {
    width: 100%;
  }

  ol.navigation {
    width: 100%;
  }
}

#MyDNA .step-title {
  position: relative;
  border-bottom: 1px dashed #000;
}

#MyDNA .step-title,
active {
  z-index: 100;
}

#MyDNA .step-title:before {
  content: attr(data-text);
  font-size: 16px;
  font-weight: 500;
  position: absolute;
  top: 38px;
  left: -137.5px;
  margin-left: 15px;
  width: 275px;
  padding: 10px;
  border-radius: 5px;
  background: var(--theme-color);
  color: #fff;
  text-align: center;
  display: none;
  z-index: 100;
}

#MyDNA .step-title:after {
  content: "";
  position: absolute;
  transition: all 0.3s ease-in;
  top: 18px;
  left: calc(50% - 10px);
  border-top: 10px solid transparent;
  border-bottom: 10px solid var(--theme-color);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  display: none;
  z-index: 100;
}

#MyDNA .step-title:hover:before,
#MyDNA .step-title:hover:after {
  display: block;
}

figure {
  margin: 0;
}

figure img {
  border-radius: 10px;
}

figure.image-only img {
  margin-top: 0;
}

figure.image-only img:nth-child(1) {
  margin-bottom: 20px;
}

.slider-content {
  width: 100%;
  margin: 2rem auto 0 auto;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.slider-content img {
  box-shadow: none;
}

.slider-content p {
  margin-top: 1rem;
}

.ic-table,
.contact-table {
  width: 90%;
  margin: 20px auto 0 auto;
  border-radius: 8px;
  border-spacing: 0;
}

.ic-table td,
.contact-table td {
  border: solid 1px #ccc;
  padding: 0.5rem;
}

.ic-table .header-row,
.contact-table .header-row {
  background-color: var(--theme-color);
  font-weight: 600;
  font-size: 20px;
  border: solid 1px var(--theme-color);
}

td.ic-code {
  width: 8%;
  font-weight: 600;
  text-align: center;
}

.contact-table td:nth-child(1) {
  width: 30%;
  text-align: left;
  font-weight: 600;
}

td.ic-definition {
  width: 92%;
}

/* ///////// FOOTER //////////// */
#MyDNA footer.mydna {
  width: 100%;
  padding: 2rem 0;
  background-color: #2a3238;
  color: #e4e6e6;
}

@media screen and (max-width: 768px) {
  #MyDNA footer.mydna {
    padding: 2rem;
  }

  #MyDNA footer.mydna div {
    width: 100%;
  }
}

#MyDNA footer.mydna h4 {
  margin: 0 0 5px 0;
  border-bottom: solid 0.5px #e4e6e6;
  padding-bottom: 5px;
  width: fit-content;
  color: rgb(228, 230, 230) !important;
  font-size: 16px !important;
  font-weight: bold;
}

#MyDNA footer.mydna ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#MyDNA footer.mydna li {
  padding: 2px 0;
  font-size: 13px;
}

#MyDNA footer.mydna a {
  text-decoration: none;
  color: #e4e6e6;
}

#MyDNA footer.mydna a:hover {
  color: #ffd65a;
}

#mydnaHomeLink a:hover {
  background-color: #130999;
  transform: scale(1.15);
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px !important;
}

#mydnaHomeLink b:hover {
  background-color: #130999;
  transform: scale(1.15);
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px !important;
}

#MyDNA .mydnaHome {
  padding: 20px;
  color: #130999;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  transition: 0.2s;
  border-radius: 10px;
}

#MyDNA a.active {
  background-color: var(--theme-color);
  transform: scale(1.15);
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}

/* .swiper-slide figcaption {
    position: absolute;
    width: 98%;
    bottom: 0;
    background-color: rgba(209, 0, 0, .8);
    color: #fff;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    border-radius: .5rem .5rem 0 0;
    padding: 10px 20px;
    left: 0;
    transform: translate3d(10px, 100%, 0);
    opacity: 0;
    transition: all .8s ease-in-out .4s;
}

.swiper-slide-active figcaption {
    transform: translate3d(10px, 0, 0);
    opacity: 1;
}*/

/* 
#main-header {
    width: 100%;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .25rem;
    background: rgba(255, 255, 255, 0.65);
}

@media screen and (max-width: 800px) {

    .padded,
    #main-header {
        padding: .5rem;
    }
}

#main-header img {
    max-width: 100%;
    height: auto;
}

.nav {
    width: 100%;
    margin: 1rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.nav .brand {
    display: inline-block;
    width: 10%;
    margin-left: 1rem;
}

.nav ul {
    list-style-type: none;
    width: 70%;
    margin: 0;
    padding: 0;
}

.nav li {
    display: inline;
}

.nav a {
    text-decoration: none;
    font-size: .9rem;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 .5rem;
    color: var(--blue-mydna);
    background-color: transparent;
    transition-timing-function: ease-in-out;
    transition: 0.2s;
}


.nav a:hover,
.nav a.active {
    background-color: var(--theme-color);
    transform: scale(1.05);
    color: #fff;
} */

#MyDNA .mydna-home {
  display: unset !important;
}

.mydna .ant-layout {
  background: inherit !important;
}

#MyDNA .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-bottom: none !important;
}

#MyDNA .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active {
  border-bottom: none !important;
}

#MyDNA .ant-menu-item,
.ant-menu-submenu-title {
  transition: none !important;
}

.myDnaTopLanding .page-title {
  font-size: clamp(1.75rem, 4vw, 58px) !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  font-weight: 900 !important;
  color: var(--theme-color) !important;
  -webkit-text-stroke: 0.5px var(--grey) !important;
}

.myDnaTopLanding h3 {
  font-size: 26px;
  font-weight: 600;
  color: var(--grey);
}

.myDnaTopLanding .sub-title {
  margin-top: 0px !important;
}

.myDnaTopLanding h2 {
  font-size: 45px;
  font-weight: bold !important;
}
body .myDnaPage .main-landing {
  font-family: var(--primary-ff);
  color: var(--black);
  line-height: normal;
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url("../../../images/my-dna-bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body .myDnaPage {
  font-family: var(--primary-ff) !important;
  color: var(--black) !important;
}

body .myDnaTopLanding main {
  flex-flow: column;
  padding-top: 5%;
  min-height: 90vh;
}

.myDnaPage .mydna-portal,
.myDnaPage .parts-u-portal {
  width: 375px;
  min-width: 385px;
  margin: 5%;
  text-align: center;
}

.mydna-portal p,
.parts-u-portal p {
  width: 90%;
  font-size: 18px;
  font-weight: 500;
  margin: 0 auto;
}

.portal-wrapper {
  position: relative;
  height: 375px;
  width: 412px;
  min-width: 412px;
}

.border-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.shape {
  stroke-dasharray: 0 540;
  stroke-dashoffset: -474;
  stroke-width: 1px;
  stroke: #ffc82f;
  border-radius: 100%;
  background: transparent;
  transition: stroke-width 0.5s, stroke-dashoffset 0.5s, stroke-dasharray 0.5s;
}

.mydna-portal a,
.parts-u-portal a {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
  width: 330px;
  min-width: 330px;
  height: 330px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.portal-wrapper:hover .shape {
  stroke-width: 6px;
  stroke-dashoffset: 0;
  stroke-dasharray: 2000;
  border-radius: 100%;
}

.mydna-portal a {
  background: url("../../../images/mydna-portal.jpg") no-repeat;
  background-size: 100%;
  background-position: center;
}

.parts-u-portal a {
  background: url("../../../images/parts-u-portal.jpg") no-repeat;
  background-size: 100%;
  background-position: center;
}

.mydna-portal:hover a,
.parts-u-portal:hover a {
  background-size: 115%;
}

.adult-learning,
.onboarding,
.sodoto,
.learning-styles {
  position: relative;
  width: 350px;
  height: 350px;
  margin: 50px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
}

.mgr-portal {
  width: 275px;
  height: 275px;
  margin: 10px auto;
  transition: all 0.2s ease-in-out;
}

.sodoto .mgr-portal {
  border-radius: 50%;
}

.adult-learning .mgr-portal {
  background: url("../../../images/new-adult-learning-icon@2x.png") no-repeat;
  background-size: 100%;
  background-position: center;
}

.adult-learning .mgr-portal:hover {
  background: url("../../../images/new-adult-learning-icon-hover@2x.png");
  background-size: 100%;
  background-position: center;
  transform: scale(1.1);
}

.onboarding .mgr-portal {
  background: url("../../../images/new-onboarding-icon@2x.png") no-repeat;
  background-size: 100%;
  background-position: center;
}

.onboarding .mgr-portal:hover {
  background: url("../../../images/new-onboarding-icon-hover@2x.png");
  background-size: 100%;
  background-position: center;
  transform: scale(1.1);
}

.sodoto .mgr-portal {
  background: url("../../../images/sodoto-icon@2x.png") no-repeat;
  background-size: 100%;
  background-position: center;
}

.sodoto .mgr-portal:hover {
  background: url("../../../images/sodoto-icon-hover@2x.png");
  background-size: 100%;
  background-position: center;
  transform: scale(1.1);
}

.learning-styles .mgr-portal {
  background: url("../../../images/learning-styles-icon@2x.png") no-repeat;
  background-size: 100%;
  background-position: center;
}

.learning-styles .mgr-portal:hover {
  background: url("../../../images/learning-styles-icon-hover@2x.png");
  background-size: 100%;
  background-position: center;
  transform: scale(1.1);
}

.highlight-box {
  width: 80%;
  margin: 3% auto;
}

.highlight-box h4 {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 900;
  color: var(--parts-managers) !important;
}

.img-page-title {
  display: block;
  width: 500px;
  margin: 0 auto;
}

.flex-item-30 {
  position: relative;
  width: 28%;
  padding: 15px;
}

.flex-column {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .flex-item-30 {
    width: 100%;
  }
}

.learning-styles-descriptions h3 {
  font-weight: 900;
  margin: 0;
}

.learning-styles-descriptions .flex-item-30:nth-child(1) h3 {
  color: var(--communicate);
}

.learning-styles-descriptions .flex-item-30:nth-child(2) h3 {
  color: var(--sales-tips);
}

.learning-styles-descriptions .flex-item-30:nth-child(3) h3 {
  color: var(--place-order);
}

/* Style the list */
ul.breadcrumb {
  padding: 0 0 20px 0;
  list-style: none;
  background-color: transparent;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 14px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: var(--blue);
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: var(--place-order);
  text-decoration: underline;
}

body .parts-managers h3 {
  font-weight: 900 !important;
  color: var(--parts-managers) !important;
}

body .parts-managers .header4 {
  color: black !important;
  font-weight: 500;
  font-size: 16px !important;
}

@media screen and (max-width: 768px) {
  .content-box {
    padding: 2rem;
  }
}

.checklist-thumb {
  margin: 10px auto;
}

.checklist-thumb .partsu-checklist {
  width: 351px;
  height: 454px;
  margin: 0 auto;
  border-radius: 10px;
  background: url("../../../images/parts-u-checklist-thumb.jpg") no-repeat;
  background-size: 100%;
  background-position: center;
  transition: all 0.2s ease-in-out;
}

.checklist-thumb .mydna-checklist {
  width: 351px;
  height: 454px;
  margin: 0 auto;
  border-radius: 10px;
  background: url("../../../images/mydna-checklist-thumb.jpg") no-repeat;
  background-size: 100%;
  background-position: center;
  transition: all 0.2s ease-in-out;
}

.checklist-thumb .mydna-checklist:hover,
.checklist-thumb .partsu-checklist:hover {
  background-size: 100%;
  background-position: center;
  transform: scale(1.1);
}
