/* eslint-disable */
@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto+Condensed|Work+Sans:400,600,700,900&display=swap|Noto+Sans:wght@400;700&display=swap");
@import url("https://netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

* {
  /* color: #808285; */
}

body {
  font-family: 'Noto Sans', sans-serif;
  color: #808285 !important;
  /* background-color: #c4c6c8 !important; */
}

h1 {
  font-family: 'Noto Sans', sans-serif !important;
  color: #808285 !important;
}

.display-linebreak {
  white-space: pre-line;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans', sans-serif !important;
  color: #808285 !important;
}

.capitalize-title {
  text-transform: capitalize;
}

h2 .auth-banner {
  /* background-image: url("./images/storefront-1.jpg");
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: #207dff;
  color: white;
  height: 100vh;
}

.ant-btn-primary * {
  color: white !important;
}

/* .ant-menu-item-active {
  border-bottom-color: #eeb100 !important;
} */

/* .ant-menu-item-selected {
  border-bottom-color: #eeb100 !important;
} */

.k-menu {
  width: 100%;
  z-index: 100;
  /* position: absolute; */

  display: flex;
  justify-content: space-between;
  background-color: white !important;
}

.k-menu-white {
  width: 100%;
  z-index: 100;

  display: flex;
  justify-content: space-between;
  background-color: transparent !important;
}

.k-menu-com {
  background-color: white !important;
}

.k-menu-com * {
  background-color: white !important;
  color: #3d3d3d !important;
}

.k-menu-white * {
  color: white;
}

.k-menu * {
  color: #1c3664 !important;
}

.vh100 {
  min-height: 100% !important;
}

.vw100 {
  min-width: 100vw !important;
}

.switch-wrapper {
  position: relative;
  /* height: 100%;
  width: 100%; */
  background-color: white !important;
}

.layout {
  width: auto;

  height: auto;
}

.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw !important;
  float: left;
  z-index: 100;
  /* background-color: #676767 !important; */
}

.dashboardmachinestrack,
.footer-key-terms,
.dashboard {
  background-color: white !important;
}

.dashboardmachines,
.dashboard {
  background-color: white !important;
}

.dashboardmachines>div>img,
.footer-key-terms>div>img {
  -webkit-filter: invert(1) !important;
  filter: invert(1) !important;
}

.dashboardcommoditiesfilters>div>img,
.dashboardcommoditiesbatteries>div>img,
.dashboardcommoditieshydraulics>div>img,
.dashboardcommoditiesreman>div>img,
.dashboardcommoditiesundercarriage>div>img,
.dashboardcommoditieslubricants>div>img {
  -webkit-filter: invert(0) !important;
  filter: invert(0) !important;
}

.dashboardmachinestrack>.copyright {
  color: #c4c6c8;
}

.dashboardmachinestrack>.copyright {
  color: #c4c6c8;
}

.footer-wheeled-harvester {
  background-color: #c4c6c8 !important;
}

.footer-wheeled-harvester>* {
  color: white !important;
}

.footer-wheeled-harvester>* {
  color: white !important;
}

.footer-wheeled-harvester>div>img {
  -webkit-filter: invert(0) !important;
  filter: invert(0) !important;
}

.dashboardmachinestrack>div>img,
.dashboard>div>img {
  -webkit-filter: invert(1) !important;
  filter: invert(1) !important;
}

.dashboardmachineslearn {
  background-color: #c4c6c8 !important;
}

.dashboardmachineslearn>* {
  color: white !important;
}

.dashboardmachineslearn>* {
  color: white !important;
}

.footer-batteries>*,
.footer-filters>*,
.footer-hydraulics>*,
.footer-lubricants,
.footer-reman,
.footer-undercarriage,
.dashboardcommoditiesbatteries,
.dashboardcommoditieshydraulics,
.dashboardcommoditiesfilters,
.dashboardcommoditieslubricants,
.dashboardcommoditiesreman,
.dashboardcommoditiesundercarriage {
  color: white !important;
}

.footer-batteries,
.dashboardcommoditiesbatteries {
  background-color: #2bb673 !important;
}

.footer-hydraulics,
.dashboardcommoditieshydraulics {
  background-color: #ff0000 !important;
}

.footer-filters,
.dashboardcommoditiesfilters {
  background-color: #f37021 !important;
}

.footer-lubricants,
.dashboardcommoditieslubricants {
  background-color: #eeb100 !important;
}

.footer-reman,
.dashboardcommoditiesreman {
  background-color: #00a7e1 !important;
}

.footer-undercarriage,
.dashboardcommoditiesundercarriage {
  background-color: #7030a0 !important;
}

.footer-sr-drive-system,
.dashboardcommoditiessrDriveSystem {
  background-color: #99e866 !important;
}

/* .footer-wheel-loader,
.footer-excavator,
.footer-dozer,
.footer-haul-truck,
.footer-profile {
  background-color: white !important;
} */

.footer-sub-commodities {
  background-color: white;
}

/* .footer-wheel-loader > *,
.footer-excavator > *,
.footer-dozer > *,
.footer-haul-truck > * {
  color: #c4c6c8 !important;
} */

.footer-sub-commodities>* {
  color: #c4c6c8;
}

.footer-sub-commodities>div>img {
  -webkit-filter: invert(0);
  filter: invert(0);
}

.footer-profile>div>img {
  -webkit-filter: invert(0.2);
  filter: invert(0.2);
}

/* .footer-how-it-works {
  background-color: white !important;
} */

/* .footer-how-it-works > div > img {
  -webkit-filter: invert(1) !important;
  filter: invert(1) !important;
} */

.switch-wrapper>div {
  position: absolute;
  width: 100% !important;
}

.layout-container {
  min-width: 100vw;
  min-height: 70vh !important;
}

.ant-progress-inner {
  border: 1px solid lightgrey;
}

.ping-dot {
  background-color: #1c3664;
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.link {
  text-decoration: none;
  color: black;
}

.link:hover {
  text-decoration: none;
  color: black;
}

.left-learning-menu {
  min-height: 100px;
}

#commodities-title {
  color: white !important;
  font-weight: 700;
  font-family: "Work Sans", sans-serif !important;
  font-size: 28px;
}

.left-learning-menu-container {
  min-height: 500px;
}

.left-menu-link {
  -webkit-text-decoration-line: none !important;
  text-decoration-line: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}

.left-menu-link>h6 {
  font-size: 18px;
  color: #707070;
  transition: ease 0.2s;
}

.link-list:hover>.left-menu-link>h6 {
  font-size: 14px;
}

.ml-com-icon {
  opacity: 0;

  position: absolute;
  right: 15px;
  height: 75px;
  width: 75px;
}

.left-menu-link:hover>h6 {
  font-size: 24px !important;
}

.left-menu-link:hover>div {
  opacity: 1;
}

.link-list {
  /* height: 300px; */
  cursor: pointer;
}

.quiz-contain>.ant-modal-content>.ant-modal-body>.ant-modal-confirm-body-wrapper>.ant-modal-confirm-btns {
  display: none !important;
}

.link-list * {
  color: #1c3664 !important;
}

.ant-popover-arrow {
  display: none !important;
  /* background-color: #1c3664 !important; */
  position: relative;
  border-color: #00a7e1 !important;
  z-index: -1;
}

.k-blue {
  color: #140a9a !important;
}

.overlay-bird {
  background: #000;
  width: 100%;
  height: 100vh;
  z-index: 101;
  top: 0;
  left: 0;
  position: fixed;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  pointer-events: none;
}

.overlay-mode-0 {
  opacity: 0;
}

.overlay-mode-1 {
  opacity: 0.5;
}

.vh-80 {
  min-height: 80vh !important;
}

.k-blue-banner {
  z-index: 0;
  overflow: hidden;
  height: 65vh !important;
  width: 100vw;
  position: absolute !important;

  background-color: #1c3664 !important;
}

.commodity-card {
  width: 200px;
  padding: 15px;
}

.commodity-card-img {
  min-height: 200px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
}

.commodity-card-column {
  min-height: 100px;
}

/* .left-menu-link:hover * {
  color: blue !important;
} */

.commodity-tip-hover {
  border-radius: 25px !important;
  border-width: 1px !important;
  border-color: grey !important;
  border-style: solid;
  height: 50px !important;
  width: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.commodity-tip-hover:hover {
  border-color: #0067b1 !important;
  color: #0067b1 !important;
}

.commodity-tip-hover:hover>div {
  opacity: 1;
  color: #0067b1 !important;
  font-weight: bold;
}

.commodity-tip-link-left,
.commodity-tip-link-right {
  transition: 0.4s ease;
  opacity: 0;
  width: 200px;
}

.commodity-tip-link-left {
  right: 100px;
  text-align: right;
}

.commodity-tip-link-right {
  left: 100px;
}

.commodity-tip-hover:hover>.commodity-tip-right {
  opacity: 1 !important;
}

.commodity-tip-hover:hover>.commodity-tip-left {
  opacity: 1 !important;
}

.commodity-tip-right {
  opacity: 0;
  position: absolute !important;
  top: -50px;
  right: -100px !important;
  transition: 0.3s ease opacity;
}

.commodity-tip-left {
  opacity: 0;
  position: absolute !important;
  top: -50px;
  left: -100px !important;
  transition: 0.3s ease opacity;
}

.commodity-tooltips {
  height: 400px;
}

.commodity-tooltips-right div:nth-child(2),
.commodity-tooltips-right div:nth-child(3) {
  transform: translateX(50px) !important;
}

.commodity-tooltips-left div:nth-child(2),
.commodity-tooltips-left div:nth-child(3) {
  transform: translateX(-50px) !important;
}

.commodities-row {
  width: 80vw;
}

#forestry {
  background-color: lightgray;
  border-radius: 200px;
  top: 15%;
  height: 400px;
  width: 400px;
  position: absolute !important;
  z-index: -1;
}

.machine {
  /* height: 70vh !important; */
  cursor: pointer;
  color: #808285;
}

@media only screen and (min-width: 1440px) {
  .machine {
    /* height: 60vh !important; */
  }
}

.machine-hover {
  opacity: 0;
  transition: ease-in-out 0.15s;
  color: #808285;
}

.machine:hover .machine-hover {
  opacity: 1;
}

.machine-landing {
  /* height: 75vh; */
  padding-top: 15px;
}

.ant-carousel {
  /* max-width: 100px;
  max-height: 100px; */
}

/* .ant-carousel .slick-slide {
  text-align: center;
  width: 100px;
  line-height: 100px;
  background: grey;
  overflow: hidden;
} */
.ant-table-thead {
  background-color: #fff;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.machine-img {
  height: 55vh;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.machine-name {
  text-align: center;
  font-weight: 600;
  font-family: "Work Sans", sans-serif !important;
}

.machine-landing-titles-coming-soon {
  position: absolute !important;
  bottom: -120px !important;
  overflow: hidden !important;
  white-space: pre-line !important;
}

.machine-landing-titles {
  position: absolute !important;
  bottom: -60px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.machine-learning-img {
  height: 600px;
  width: 900px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom !important;
  overflow: visible;
}

.ant-popover-inner {
  border: 2px #00a7e1 solid !important;
  border-radius: 10px !important;
}

.machine-learning-xray-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trunc {
  max-width: 200px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.batteries-color {
  color: #2bb673 !important;
}

.batteries-color:hover * {
  color: #2bb673 !important;
}

.filters-color {
  color: #f37021 !important;
}

.filters-color:hover * {
  color: #f37021 !important;
}

.hydraulics-color {
  color: #ff0000 !important;
}

.hydraulics-color:hover * {
  color: #ff0000 !important;
}

.lubricants-color {
  color: #eeb100 !important;
}

.lubricants-color:hover * {
  color: #eeb100 !important;
}

.reman-color {
  color: #00a7e1 !important;
}

.reman-color:hover * {
  color: #00a7e1 !important;
}

.undercarriage-color {
  color: #7030a0 !important;
}

.undercarriage-color {
  color: #7030a0 !important;
}

.sr-drive-system-color {
  color: #99e866 !important;
}

.sr-drive-system-color:hover * {
  color: #99e866 !important;
}

.under-color {
  color: #7030a0 !important;
}

.under-color:hover * {
  color: #7030a0 !important;
}

.batteries-still-color * {
  color: #2bb673 !important;
}

.filters-still-color * {
  color: #f37021 !important;
}

.hydraulics-still-color * {
  color: #ff0000 !important;
}

.lubricants-still-color * {
  color: #eeb100 !important;
}

.reman-still-color * {
  color: #00a7e1 !important;
}

.under-still-color * {
  color: #7030a0 !important;
}

.route-link:hover * {
  color: blue !important;
}

.my-modal {
  width: 90vw !important;
  /* width: 100% !important;
  height: 100% !important; */
}

.machine-learning-xray-commodity-img,
.machine-learning-xray-img {
  opacity: 1;
  position: absolute;
  margin-bottom: 30px;
  margin-top: 30px;
  min-height: 100%;
  width: 100%;
  transform: translate(-5%, -5%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}

.machine-xray-imgs {
  position: absolute;
  min-height: 600px;
  width: 100%;
}

.opacity-1 {
  opacity: 1 !important;
  transition: 0.5s ease !important;
}

.opacity-05 {
  opacity: 0.5 !important;
  transition: 0.5s ease !important;
}

.opacity-03 {
  opacity: 0.3 !important;
  transition: 0.5s ease !important;
}

.opacity-0 {
  opacity: 0 !important;
  transition: 0.5s ease !important;
}

.mlshow {
  transform: scale(1);
  transition: 0.5s ease !important;
}

.mlhide {
  transform: scale(0);
  transition: 0.5s ease !important;
}

/* .layout__wrapper {
  background-image: linear-gradient(
    0deg,
    hsl(215, 14%, 16%) 0%,
    hsl(215, 19%, 29%) 100%
  );
  border-radius: 3px;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.25);
  margin: 3rem auto;
  max-width: 370px;
} */

.app-bar {
  border-radius: 3px;
}

.app-bar__title {
  color: var(--color-on-background);
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
}

.list {
  list-style: none;
  margin: 0;
  min-height: 1.5rem;
  padding: 0;
  position: relative;
}

.list__item {}

.card {
  background-color: hsl(215, 14%, 37.5%);
  border-radius: var(--border-radius-500);
  cursor: pointer;
  color: hsl(228, 19%, 98%);
  padding: 0.66rem 1rem;
  position: relative;
}

.list__item+.list__item {
  margin-top: 0.5rem;
}

.holder {
  padding: 1rem 1.5rem;
}

.holder__title {
  background-color: #1da5f9;
  background-image: linear-gradient(90deg, #028ce1 0%, #6acbe0 100%);
  border-radius: var(--border-radius-500);
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
}

.holder--tint-2 .holder__title {
  background-image: linear-gradient(90deg, #2fd4bd 0%, #66e887 100%);
}

.holder--tint-3 .holder__title {
  background-image: linear-gradient(90deg, #fe8080 33%, #fea380 100%);
}

.wordmatch-quiz-image {
  position: relative !important;
  height: 100px;
  width: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.wordmatch-quiz-text {
  position: relative !important;
  background-color: white;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.wordmatch-droppable {
  /* min-height: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wordmatch-list {
  position: relative !important;
}

.wordmatch-item {
  min-height: 60px !important;
}

.commodity-learning-shelf {
  margin-bottom: 30px;
  margin-top: 30px;
  height: 600px;
  width: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
}

.commodity-shelf-item {
  position: absolute;
  cursor: pointer;
  height: 200px;
  width: 200px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 200px 200px;
}

.commodity-shelf-item-label {
  position: absolute;
  bottom: -17px;
  left: 50%;
  white-space: nowrap;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  transform: translateX(-50%);
  font-size: 10px;
  background-color: white;
  border-width: 2px;
  border-style: solid;
  border-color: #adaeb0;
}

.word-find-item-easy {
  /* width: 5%; */
}

.word-area {
  z-index: 3;
  position: relative;
  width: 500px;
  height: 320px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.hightlight-me {
  color: green;
  background-color: red;
}

.caption {
  color: grey !important;
  font-size: 16px;
}

.pointer-cursor {
  cursor: pointer;
}

.offering-img {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  height: 150px;
  width: 150px;
}

.wheel-pick {
  overflow: hidden;
}

.wheel-pick>ul {
  text-align: left;
  overflow-y: scroll;
  background-color: transparent !important;
}

.wheel-pick>li {
  align-items: flex-start !important;
}

.sc-AxhCb::-webkit-scrollbar {
  display: none;
}

.sc-AxiKw {
  font-size: 24px;
}

.bg-pos-top {
  background-position: top;
}

.bg-pos-bottom {
  background-position: bottom;
}

.bg-pos-bottom-percent {
  background-position: right 0px bottom -80px !important;
}

.bg-pos-center {
  background-position: center;
}

#scene {
  /* left: -3rem; */
  z-index: 1;
  overflow: hidden;
  height: 60vh !important;
  width: 100vw;
  position: absolute !important;
  background-color: #0067b1;
}

.yellow-banner {
  /* left: -3rem; */
  z-index: 1;
  overflow: hidden;
  height: 55vh !important;
  width: 100vw;
  position: absolute !important;
  background-color: #ffd65a;
}

.page>* {
  z-index: 2 !important;
}

.page>.ant-row>* {
  z-index: 2 !important;
}

.ant-menu-horizontal {
  border-bottom: 0px !important;
}

.machL {
  width: 100%;
  min-height: 76vh !important;
  background-color: #c4c6c8;
}

.machL>* {
  z-index: 2;
}

.ant-menu-item-selected,
.ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
}

.ant-menu {
  font-size: 16px;
}

.parallax-bg {
  margin-left: -5%;
  background-repeat: no-repeat;
  background-size: 100% 110%;
  width: 110%;
  height: 110%;
}

.text-dark-blue {
  color: #1c3664 !important;
}

.parallax-forestry>div {
  background-position: 0px 60px !important;
}

.parallax-track>div:nth-child(1) {
  background-position: 0px 60px !important;
}

.parallax-track>div {
  background-position: 0px 80px !important;
}

.parallax-other {
  background-repeat: no-repeat;
  width: 109%;
  height: 105%;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

/* .parallax-wheel > div {
  background-position: 0px -50px !important;
}

.parallax-wheel > div:last-child {
  background-position: 0px -200px !important;
}
.parallax-wheel > div:nth-child(5) {
  background-position: -100px -150px !important;
}

.parallax-wheel > div:nth-child(4) {
  background-position: 0px -100px !important;
} */

.color-black {
  color: black;
}

.frost-container {
  border-radius: 5px;
  position: relative;
  z-index: 1 !important;
  background: inherit;
  overflow: hidden;
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
}

.frost-container-light {
  box-shadow: inset 1000px 1000px 1000px rgba(255, 255, 255, 0.7);
}

.frost-container-dark {
  box-shadow: inset 1000px 1000px 1000px rgba(0, 0, 0, 0.7);
}

.frost-container-dark * {
  color: white !important;
}

.ant-col {
  transition: width 2s !important;
}

.page {
  position: relative;
  background-color: white !important;
  min-height: 85vh !important;
  flex: 1;
}

.commodity-bg {
  background-image: url("./assets/images/commodity-bg.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1000px) {
  .commodity-bg {
    background-size: cover;
  }
}

.image-icon {
  height: 40px;
  width: 40px;
}

.ant-menu-submenu-title>a {
  color: #1c3664 !important;
}

.eyehover:hover {
  content: url("./assets/images/eye-icon-green@3x.png");
}

.eyeuc:hover {
  content: url("./assets/images/eye-icon-purple@3x.png");
}

#test-your-knowledge {
  position: absolute;
  right: 50px;
  bottom: 0px;
  z-index: 100;
  cursor: pointer;
}

a:hover {
  text-decoration: none !important;
}

.offering-contain {
  height: 300px;
  display: flex;
  justify-content: center;
  position: relative;
}

.offering-contain * {
  transition: 0.2s ease;
}

.offering-contain>div>div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.offering-contain>div>p {
  opacity: 0 !important;
  display: none;
  font-size: 14px;
  padding: 5px;
}

.offering-contain:hover>div>p {
  opacity: 1 !important;
  display: block;
}

.offering-contain:hover>div {
  position: absolute;
  left: 15%;
  transform-origin: right !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 500px;
  z-index: 2 !important;
  border: 1px solid red;
}

.offering-contain:hover .offering-img {
  height: 250px;
  width: 250px;
}

.offering-batteries-border:hover>div {
  border: 1px solid #2bb673 !important;
}

.offering-filters-border:hover>div {
  border: 1px solid #f37021 !important;
}

.offering-hydraulics-border:hover>div {
  border: 1px solid #fff000 !important;
}

.offering-lubricants-border:hover>div {
  border: 1px solid #eeb100 !important;
}

.offering-reman-border:hover>div {
  border: 1px solid #00a7e1 !important;
}

.offering-undercarriage-border:hover>div {
  border: 1px solid #863399 !important;
}

.offering-sr-drive-system-border:hover>div {
  border: 1px solid #99e866 !important;
}

.filters-icon-safety-bg {
  background-image: url(./assets/images/filters-safe-bg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* .batteries-terms-bg {
  background-image: url(./assets/images/);
}

.filters-terms-bg {
  background-image: url(./assets/images/);
}

.hydraulics-terms-bg {
  background-image: url(./assets/images/);
}

.lubricants-terms-bg {
  background-image: url(./assets/images/);
}

.reman-terms-bg {
  background-image: url(./assets/images/);
} */
.undercarriage-terms-bg {
  background-image: url(./assets/images/kt-under.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.lubricants-terms-bg {
  background-image: url(./assets/images/kt-lube.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.reman-terms-bg {
  background-image: url(./assets/images/kt-reman.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.filters-terms-bg {
  background-image: url(./assets/images/kt-filters.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.batteries-terms-bg {
  background-image: url(./assets/images/kt-batteries.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.hydraulics-terms-bg {
  background-image: url(./assets/images/kt-hydraulics.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.mates-chart {
  border: 1px solid lightgrey;
}

.mates-chart>div:nth-child(1) {
  padding: 5px;
  background-color: #1c3664;
  color: white;
}

.mates-chart>div:nth-child(2) {
  white-space: pre-line;
  padding: 15px;
}

.mates-chart-alt {
  margin-top: 15px;
  border: 1px solid lightgrey;
}

.mates-chart-alt>div:nth-child(1) {
  padding: 5px;
  background-color: #edeeef;
  color: #1c3664;
}

.mates-chart-alt>div:nth-child(2) {
  white-space: pre-line;
  padding: 15px;
  font-size: smaller;
}

.mates-chart-alt>div:nth-child(3) {
  white-space: pre-line;
  border-top: 1px dotted lightgrey;
  padding: 15px;
  font-size: smaller;
}

.hotspot-drop {
  /* margin-bottom: 30px;
  margin-top: 30px; */
  min-height: 500px;
  width: 650px;
  background-position: bottom !important;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.hotspot-item {
  /* border: 1px solid black; */
}

.hotspot-style {}

.button--microsoft {
  background-color: #00bcf2;
}

.button--microsoft:hover {
  background-color: #0094bf;
}

.button--microsoft:before {
  content: "\f17a";
  font-family: "FontAwesome";
  padding-right: 1rem;
}

.login-button {
  display: block;
  color: white;
  padding: 1rem;
  text-decoration: none;
  margin: 1rem 0;
  transition: all 0.3s ease;
}

.quiz-modal>div:nth-child(1) {
  width: 95vw !important;
}

.modal-70vw {
  width: 70vw !important;
}

.modal-80vw {
  width: 80vw !important;
}

.learn-about-wrap {
  position: relative;
}

.learn-about-wrap>p {
  opacity: 0;
  position: absolute;
  left: -100px;
  width: 300px;
}

.learn-about-wrap:hover>p {
  color: white !important;
  opacity: 1;
}

.commodity-learning-shelf>*:nth-child(1),
.commodity-learning-shelf>*:nth-child(2),
.commodity-learning-shelf>a:nth-child(3)>div {
  transform: translateY(-40px) !important;
}

.commodity-learning-shelf>a:nth-child(4)>div,
.commodity-learning-shelf>a:nth-child(5)>div,
.commodity-learning-shelf>a:nth-child(6)>div {
  transform: translateY(-25px) !important;
}

.commodity-learning-shelf>*:nth-child(7),
.commodity-learning-shelf>*:nth-child(8) {
  transform: translateY(-20px) !important;
}

.batteries-border {
  border-color: #2bb673 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.hydraulics-border {
  border-color: #ff0000 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.filters-border {
  border-color: #f37021 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.lubricants-border {
  border-color: #eeb100 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.reman-border {
  border-color: #00a7e1 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.undercarriage-border {
  border-color: #7030a0 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.sr-drive-system-border {
  border-color: #99e866 !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.wheeledharvester-hover-img:hover {
  content: url("./assets/images/HarvesterIconHOVER@2x.png");
}

.fellerbuncher-hover-img:hover {
  content: url("./assets/images/FellerBuncherIconHOVER@3x.png");
}

.forwarders-hover-img:hover {
  content: url("./assets/images/ForwarderIconHOVER@3x.png");
}

.forestryexcavator-hover-img:hover {
  content: url("./assets/images/ForestryExcavatorIconHOVER@3x.png");
}

.excavator-hover-img:hover {
  content: url("./assets/images/ExcavatorIconHOVER@3x.png");
}

.dozer-hover-img:hover {
  content: url("./assets/images/DozerIconHOVER@3x.png");
}

.wheelloader-hover-img:hover {
  content: url("./assets/images/WheelLoaderIconHOVER@3x.png");
}

.haultruck-hover-img:hover {
  content: url("./assets/images/HaulTruckIconHOVER@3x.png");
}

.shelf-hover-img:hover {
  content: url("./assets/images/commodities-xray-icon-hover@3x.png");
}

.size-icon-hover:hover {
  content: url("./assets/images/sizes-icon-HOVER@2x.png");
}

.sit-icon-hover:hover {
  content: url("./assets/images/see-it-in-action-icon-hover@3x.png");
}

.green-haze {
  box-shadow: 0px 0px 22px 7px rgba(40, 201, 0, 0.4);
}

.head-bg {
  background-image: url("./assets/images/quiz-head-watermark@2x.png");
  background-size: 75px 75px;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-color: rgba(0, 0, 0, 0.05);
}

.definition-border,
.questions-to-ask-border,
.warranty-border {
  border-color: darkgrey !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.definition-color {
  color: #00a7e1 !important;
}

.warranty-color {
  color: #1c3664 !important;
}

.questions-to-ask-color {
  color: #007960 !important;
}

.eyepop {
  display: none;
  transition: 0.2s ease;
  transition-delay: 1s;
  opacity: 0;
}

.eye-contain:hover>.eyepop {
  opacity: 1;
  display: block;
}

.parrot-dialog {
  width: 250px;
  position: absolute;
  left: -250px;
  font-size: small;
  z-index: 1;
  top: 50px;
}

.parrot {
  z-index: 102;
  position: fixed;
  top: 80px;
  right: -10px;
  transition: 0.3s ease;
  /* opacity: 1; */
  transform: scale(0.9);
}

.parrot-exposed {
  right: 0px;
  /* opacity: 1; */
  transform: scale(1);
}

.image-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.ant-carousel .slick-dots li {
  margin-left: 50px !important;
}

.ant-carousel .slick-dots li button {
  background: #00a7e1 !important;
  opacity: 0.4;
  border-radius: 50px;
  height: 25px !important;
  width: 25px !important;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  border-radius: 12.5px;
  background: #00a7e1 !important;
  height: 25px !important;
  width: 25px !important;
}

.ant-carousel .slick-slide {
  overflow: hidden;
  height: 500px !important;

  /* background: #364d79 */
}

.slick-dots {
  width: 70%;
  height: 25px;
  margin: 0;
}

/* user profile track progression styles */

.ant-steps-item-container {
  text-align: center;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: transparent !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #78be43 !important;
  border-color: transparent !important;
}

.anticon svg {
  display: inline-block;
  color: #fff;
  margin-top: -6px;
}

.ant-notification-notice-with-icon .anticon svg {
  color: #ff4d4f !important;
}

.ant-notification-close-x .anticon svg {
  color: #000 !important;
}

.ant-modal-close-x .anticon svg {
  color: #000 !important;
}

.ant-message-notice-content .anticon svg {
  color: #52c41a
}


.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  display: none;
}

.ant-steps-item-icon {
  margin: 5px auto;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 100%;
}

.ant-steps-item-content {
  width: 100%;
  display: block;
  text-align: center;
}



.machines-color {
  color: grey;
}

.machines-border {
  border-color: grey !important;
  border-width: 1.5px !important;
  border-style: solid !important;
}

.machine>img {
  width: 90%;
}

.machine-col {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center !important;
  height: 45vh !important;
}

.custom-p-5 {
  padding: 0 3rem !important;
}

.ant-layout {
  background-color: white !important;
  position: relative !important;
}

.wordfindsquare {
  background-color: #f7f8f8;
  height: 250px;
  width: 250px;
  border: solid 1px #c4c6c8;
  border-radius: 10px;
}

.wordfindsquare>img {
  height: 200px;
  width: 200px;
  -o-object-fit: contain;
  object-fit: contain;
}

/*div.white-space-steps>div.ant-steps-item-container>div.ant-steps-item-content>div.ant-steps-item-description {
  max-width: 130px !important;
}*/

.ant-menu-item-group-title {
  color: #333 !important;
  border-top: dotted 1px #ddd;
}

.ant-menu-vertical.ant-menu-sub {
  min-width: 225px !important;
  border-radius: 10px;
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
  border-radius: 8px !important;
}

a.myNotes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

a.myNotes img {
  display: inline-block;
}

a.myNotes p {
  display: inline-block;
  line-height: 34px;
  font-size: 20px;
  font-weight: bold;
  color: #853694;
  margin-bottom: 0px !important;
}

h3.profileProgress {
  font-weight: bold;
  font-size: 34px;
  color: #343741 !important;
}

h5.product-subtitle {
  width: 20%;
  padding-bottom: 10px;
}

.ant-message-notice-content .ant-message-warning .anticon svg {
  color: #faad14 !important;
}